<template>
  <div id="salary" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">GUIA II</v-row>
      <v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <router-link to="/addQuizz" id="nuevaPreguntaempleado">
              <button
                class="breakSearch botonAmarillo"
                style="font-size:0.70rem !important"
              >
                Asignación de Cuestionario
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="questions"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Guías por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning"
                >No hay datos en sistema ..</v-alert
              >
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">
                    <slot v-bind="item.empleado">
                      {{
                        item.empleado.nombre +
                          " " +
                          item.empleado.apellidoPaterno
                      }}
                    </slot>
                  </td>

                  <td class="text-sm-center">
                    {{ item.fechaInicio.substr(0, 10) }}
                  </td>
                  <td class="text-sm-center">
                    {{
                      item.fechaRespondido != null
                        ? item.fechaRespondido.substr(0, 10)
                        : ""
                    }}
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      size="24"
                      class="mx-2 mt-1"
                      @click="editItem(item)"
                      v-if="item.activa == true"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="red"
                      size="24"
                      class="mr-6 mt-1"
                      @click="temporal(item)"
                      v-if="item.activa == true"
                      >fa-trash-alt</v-icon
                    >
                    <!-- <a :href="serverAux + '/climaLaboralResultados/reporte-pdf-cuestionario/' + item.id" v-if="item.activa == false"><v-icon color="red" size="26" class="mt-4">far fa-file-pdf</v-icon></a> -->
                    <v-icon
                      v-if="item.activa == false"
                      color="red"
                      size="26"
                      @click="consultaDatos(item.id)"
                      class="ml-12 pl-8"
                      >far fa-file-pdf</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Nom-035</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      search: "",
      expiration: false,
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Empleado",
          align: "center",
          value: "empleado.nombre",
        },
        {
          text: "Fecha de asignación",
          align: "center",
          value: "fechaInicio",
        },
        {
          text: "Fecha de respuesta",
          align: "center",
          value: "fechaRespondido",
        },
        {
          text: "Editar  / Eliminar /  Ver ",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      questions: [],
      respuesta: "",
      id: "",
      confirmation: false,
      advice:false,
      show: false,
      referencia: 0,
      edit: false,
      vacio: false,
      question: "",
      redacto: "",
      reason: 0,
      area: 0,
      areas: [],
      enterprise: 0,
      serverAux: Server,
      dataResponse: [],
      id:0,
    };
  },
  methods: {
    editItem(item) {
      this.obtenerAreasPuestos();
      //console.log("El id es: " + item.id);
      localStorage.idCuestionario = item.id;
      this.$router.push("/editQuizz");
    },
    temporal(item){
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar el cuestionario para " +
        item.empleado.nombre +
                          " " +
                          item.empleado.apellidoPaterno
        " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      this.show=true;
      axios
        .delete(Server + "/cuestionariosGuiaUno/" + this.id,
          {
            headers: {
              Authorization: localStorage.token,
            },
          })
        .then((response) => {
          this.id = 0;
          this.respuesta = "El cuestionario fue eliminado con éxito.";
          this.show=false;
          this.listar();
          this.confirmation = true;
        })
        .catch((e) => {
          //console.log(e);
          this.show=false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = 0;
            this.respuesta = "El cuestionario no fue eliminada.";
            this.confirmation = true;
          }
        });
    },
    consultaDatos(idCuestionario) {
      this.show = true;
      axios
        .get(
          Server +
            "/climaLaboralResultados/reporte-pdf-cuestionario/" +
            idCuestionario,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.dataResponse = response.data;
          this.construyePdf();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      //console.log(this.dataResponse);
    },
    construyePdf() {
      var doc = new jsPDF("p", "pt", "letter");
      //console.log("Create PDF!!");

      doc.page = 1; // use this as a counter.
      var totalPages = 1; // define total amount of pages
      var y = 30;

      //Header
      doc.setFontSize(8); // optional
      //doc.setFontType("normal");
      var str = "Página " + doc.page + " de " + totalPages;
      doc.text(str, doc.internal.pageSize.width - 100, y); //key is the interal pageSize function
      doc.line(5, y + 10, doc.internal.pageSize.width - 5, y + 10);
      y = y + 35;
      doc.setFontSize(18);
      //doc.setFontType("bold");
      var textHeader = "Cuestionario Guía II";
      var x =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(textHeader) * doc.internal.getFontSize()) / 2;
      doc.text(textHeader, x, y);

      // Información de empleado
      y = y + 30;
      doc.setFontSize(8);
      //doc.setFontType("bold");
      var splitTitle = doc.splitTextToSize(
        "Empresa: " + this.dataResponse.empresa,
        y
      );
      doc.text(splitTitle, 30, y); // Máximo width (x)
      var splitTitle = doc.splitTextToSize(
        "Empleado: " + this.dataResponse.nombreEmpleado,
        y
      );
      doc.text(splitTitle, 170, y); // Máximo width (x)
      var splitTitle = doc.splitTextToSize(
        "Area: " + this.dataResponse.area,
        y
      );
      doc.text(splitTitle, 310, y); // Máximo width (x)
      doc.text("Puesto: " + this.dataResponse.puesto, 430, y); // Máximo width (x)
      y = y + 45;
      var textDate =
        "Fecha respuesta cuestionario: " + this.dataResponse.fechaRespondio.substr(0,9);
      var xDate =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(textDate) * doc.internal.getFontSize()) / 2;
      //doc.setFontType("normal");
      doc.text(textDate, xDate, y);
      this.dataResponse.secciones.forEach((seccion) => {
        //Secciones
        y = y + 37;
        doc.setFontSize(12);
        //doc.setFontType("bold");
        doc.text("Seccion" + " - " + seccion, 30, y);

        //Preguntas
        // Crea un array "body" para darle formato a la tabla que se enviará al pdf
        var body = [];
        var reportesArray = this.dataResponse.preguntas; // this.movimientos es tu array del API
        //console.log("preguntas", reportesArray);
        // Aquí es por si necesitas darle algún formato especial a los datos
        y = y + 15;
        reportesArray.forEach((reporte) => {
          if (reporte.pregunta.seccion == seccion) {
            body.push([
              reporte.pregunta.consecutivoGuiaPregunta,
              reporte.pregunta.pregunta,
              reporte.respuestaId == 1
                ? "Siempre"
                : reporte.respuestaId == 2
                ? "Casi Siempre"
                : reporte.respuestaId == 3
                ? "Algunas veces"
                : reporte.respuestaId == 4
                ? "Casi nunca"
                : "Nunca",
            ]);
          }
        });
        //console.log("datosFinales", body);
        // Esto crea la tabla
        var headers = ["Id", "Pregunta", "Respuesta"];
        doc.autoTable({
          startY: y,
          // Encabezados tabla
          head: [["Id", "Pregunta", "Respuesta"]],
          body: body,
          headStyles: {
            fillColor: [245, 177, 68],
          },
          margin: { top: 80 },
          /* pageBreak: 'auto',
          rowPageBreak: 'auto' */
        });
        y = doc.lastAutoTable.finalY;
      });

      // FOOTER
      // Add Page content
      let pdfName = "" + "ReporteCuestionarioGuiaII"; // Nombre del PDF
      doc.save(pdfName + ".pdf");
      this.show = false;
    },
    listar() {
      this.questions=[];
      this.show = true;
      axios
        .get(Server + "/cuestionariosGuiaUno", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          response.data.forEach((element) => {
            if (element.guiaReferencia == 2) {
              this.questions.push(element);
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerAreasPuestos() {
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>
